import io from "socket.io-client";
import feathers from "@feathersjs/client";

var socket = null;
if (process.env.NODE_ENV === "development") {
  socket = io("ssh.cityad.cat:3030");
} else {
  socket = io("");
}

//const socket = io("http://localhost:3030");
const client = feathers();

client.configure(feathers.socketio(socket));
client.configure(
  feathers.authentication({
    storage: window.localStorage,
  })
);

export default client;
