import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.9.0";

// pages for this product
import Components from "views/Components/Components.js";
import LandingPage from "views/LandingPage/LandingPage.js";
import ProfilePage from "views/ProfilePage/ProfilePage.js";
import LoginPage from "views/LoginPage/LoginPage.js";
import StartingPage from "views/StartingPage/StartingPage.js";
import ClassificationPage from "views/ClassificationPage/ClassificationPage.js";
import PhaseClassificationPage from "views/ClassificationPage/PhaseClassificationPage.js";
import MixedPhaseClassificationPage from "views/ClassificationPage/MixedPhaseClassificationPage.js";
import MatchDaysPage from "views/MatchDaysPage/MatchDaysPage.js";
import PhaseMatchDaysPage from "views/MatchDaysPage/PhaseMatchDaysPage.js";
import MixedPhaseMatchDaysPage from "views/MatchDaysPage/MixedPhaseMatchDaysPage.js";

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/landing-page" component={LandingPage} />
      <Route path="/components-page" component={Components} />
      <Route path="/classification-page/:id" component={ClassificationPage} />
      <Route
        path="/phase-classification-page/:id"
        component={PhaseClassificationPage}
      />
      <Route
        path="/mixed-phase-classification-page/:id"
        component={MixedPhaseClassificationPage}
      />
      <Route path="/matchdays-page/:id" component={MatchDaysPage} />
      <Route path="/phase-matchdays-page/:id" component={PhaseMatchDaysPage} />
      <Route path="/mixed-phase-matchdays-page/:id" component={MixedPhaseMatchDaysPage} />
      <Route path="/profile-page" component={ProfilePage} />
      <Route path="/login-page" component={LoginPage} />
      <Route path="/" component={StartingPage} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
