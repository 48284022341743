import React, { useState, useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";
import Email from "@material-ui/icons/Email";
import People from "@material-ui/icons/People";

// core components
import Header from "components/Header/Header.js";
//import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";

import client from "../../helpers/feathersClient.js";
import defines from "../../helpers/defines.js";

import moment from "moment";

import styles from "assets/jss/material-kit-react/views/profilePage.js";
import { element } from "prop-types";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

export default function MatchDaysPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);

  const [participants, setParticipants] = useState([]);
  const [matches, setMatches] = useState([]);
  const [matchDays, setMatchDays] = useState([]);
  const [selectedMatchday, setSelectedMatchday] = useState(null);
  const [competition, setCompetition] = useState({
    _id: "",
    type: null,
    participants: [],
  });
  const [matchDayDialog, setMatchDayDialog] = useState(false);
  const [removeMatchDayDialog, setRemoveMatchDayDialog] = useState(false);
  const [resultDialog, setResultDialog] = useState(false);
  const [result, setResult] = useState([0, 0]);
  const [matchId, setMatchId] = useState("");
  //const [newParticipantName, setNewParticipantName] = useState("");

  //const [maxRows, setMaxRows] = useState(1);

  function addMatchday(date, competition, participants) {
    client
      .service("matchdays")
      .create({
        date: date,
        competition: competition,
        participants: participants,
      })
      .then((matchday) => {
        var newMatchDays = [...matchDays];
        newMatchDays.unshift(matchday);
        setMatchDays(newMatchDays);
        setSelectedMatchday(matchday);
        queryMatches(matchday._id);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function removeMatchday(id) {
    client
      .service("matchdays")
      .remove(id)
      .then(() => {
        queryCompetition();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  /*function addParticipantClick() {
    client.service("participants").create({
      competition: competitionId,
      name: "Enric",
    });
  }*/

  /*function handleParticipantChange(event) {
    setNewParticipantName(event.target.value);
  }*/

  function updateCompetition(_id) {
    if (competition._id === _id) {
      queryMatchdays(_id);
    }
  }

  function queryMatches(_id) {
    client
      .service("matches")
      .find({ query: { matchday: _id } })
      .then((matches) => {
        setMatches(matches.data);
      });
  }

  function queryMatchdays(_id) {
    client
      .service("matchdays")
      .find({
        query: {
          competition: _id,
          $sort: {
            date: -1,
          },
        },
      })
      .then((matchdays) => {
        setMatchDays(matchdays.data);
        if (matchdays.data.length > 0) {
          setSelectedMatchday(matchdays.data[0]);
          queryMatches(matchdays.data[0]._id);
        }
      });
  }

  function queryCompetition() {
    client
      .service("competitions")
      .get(props.match.params.id)
      .then((comptetion) => {
        setCompetition(comptetion);
        queryMatchdays(comptetion._id);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function modifyMatch(matchId, result) {
    client
      .service("matches")
      .patch(matchId, {
        result: result,
      })
      .then((match) => {
        var newMatches = [...matches];
        const i = newMatches.findIndex((element, index, array) => {
          return element._id === match._id;
        });
        newMatches.splice(i, 1, match);
        setMatches(newMatches);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    queryCompetition();
    client.service("competitions").on("patched", (competition) => {
      updateCompetition(competition._id);
    });
  }, []);

  const handleToggle = (value) => {
    const currentIndex = participants.indexOf(value);
    const newChecked = [...participants];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setParticipants(newChecked);
  };

  return (
    <div>
      <Header
        color="transparent"
        brand="La Kompeti"
        rightLinks={
          <HeaderLinks
            id={props.match.params.id}
            type={defines.competitionTypes.COMPETITION_MIXED_PLAYERS_PERCENTAGE}
          />
        }
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal,
        }}
        open={resultDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setResultDialog(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h4 className={classes.modalTitle}>Resultat</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer>
            <GridItem xs={4}>
              <CustomInput
                //labelText={ t('translation:default_duration_s') }
                id="result1"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  type: "number",
                  value: result[0],
                  onChange: (event) => {
                    const punts = parseInt(event.target.value);
                    if (punts >= 0) {
                      setResult([punts, result[1]]);
                    }
                  },
                }}
              />
            </GridItem>
            <GridItem xs={2}>
              <p style={{ textAlign: "center" }}>{" - "}</p>
            </GridItem>
            <GridItem xs={4}>
              <CustomInput
                //labelText={ t('translation:default_duration_s') }
                id="result2"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  type: "number",
                  value: result[1],
                  onChange: (event) => {
                    const punts = parseInt(event.target.value);
                    if (punts >= 0) {
                      setResult([result[0], punts]);
                    }
                  },
                }}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            color="success"
            simple
            onClick={(event) => {
              modifyMatch(matchId, result);
              setResultDialog(false);
            }}
          >
            Modificar
          </Button>
          <Button onClick={() => setResultDialog(false)} color="danger" simple>
            Tancar
          </Button>
        </DialogActions>
      </Dialog>
      <Parallax small filter image={require("assets/img/profile-bg.jpg")} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <GridContainer
              justify="center"
              style={{ paddingTop: "30px", paddingBottom: "30px" }}
            >
              <GridItem xs={12} sm={6} style={{ textAlign: "center" }}>
                <CustomDropdown
                  buttonText="Jornades"
                  dropdownHeader="Escull jornada"
                  buttonProps={{
                    className: classes.navLink,
                    color: "transparent",
                  }}
                  dropdownList={matchDays.map((matchday) => (
                    <span
                      key={matchday.date}
                      onClick={() => {
                        queryMatches(matchday._id);
                        setSelectedMatchday(matchday);
                      }}
                    >
                      {moment(matchday.date).format("DD/MM/YYYY")}
                    </span>
                  ))}
                />
              </GridItem>
              <GridItem xs={6} sm={3} style={{ textAlign: "center" }}>
                <Button
                  color="primary"
                  simple
                  onClick={() => setMatchDayDialog(true)}
                  style={{ paddingLeft: "0px", paddingRight: "0px" }}
                >
                  Afegir jornada
                </Button>
                <Dialog
                  classes={{
                    root: classes.center,
                    paper: classes.modal,
                  }}
                  open={matchDayDialog}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={() => setMatchDayDialog(false)}
                  aria-labelledby="classic-modal-slide-title"
                  aria-describedby="classic-modal-slide-description"
                >
                  <DialogTitle
                    id="classic-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                    <h4 className={classes.modalTitle}>Seleccionar jugadors</h4>
                  </DialogTitle>
                  <DialogContent
                    id="classic-modal-slide-description"
                    className={classes.modalBody}
                  >
                    <GridContainer>
                      {competition.participants.map((participant) => (
                        <GridItem xs={6} key={participant.name}>
                          <div
                            className={
                              classes.checkboxAndRadio +
                              " " +
                              classes.checkboxAndRadioHorizontal
                            }
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  tabIndex={-1}
                                  onClick={() => handleToggle(participant.name)}
                                  checked={
                                    participants.indexOf(participant.name) !==
                                    -1
                                      ? true
                                      : false
                                  }
                                  checkedIcon={
                                    <Check className={classes.checkedIcon} />
                                  }
                                  icon={
                                    <Check className={classes.uncheckedIcon} />
                                  }
                                  classes={{
                                    checked: classes.checked,
                                    root: classes.checkRoot,
                                  }}
                                />
                              }
                              classes={{
                                label: classes.label,
                                root: classes.labelRoot,
                              }}
                              label={participant.name}
                            />
                          </div>
                        </GridItem>
                      ))}
                    </GridContainer>
                  </DialogContent>
                  <DialogActions className={classes.modalFooter}>
                    <Button
                      color="success"
                      simple
                      onClick={(event) => {
                        addMatchday(moment(), competition._id, participants);
                        setMatchDayDialog(false);
                        setParticipants([]);
                      }}
                    >
                      Afegir
                    </Button>
                    <Button
                      onClick={() => setMatchDayDialog(false)}
                      color="danger"
                      simple
                    >
                      Tancar
                    </Button>
                  </DialogActions>
                </Dialog>
              </GridItem>
              <GridItem xs={6} sm={3} style={{ textAlign: "center" }}>
                <Button
                  color="primary"
                  simple
                  onClick={() => {
                    if (selectedMatchday !== null)
                      setRemoveMatchDayDialog(true);
                  }}
                  style={{ paddingLeft: "0px", paddingRight: "0px" }}
                >
                  Eliminar jornada
                </Button>
                <Dialog
                  classes={{
                    root: classes.center,
                    paper: classes.modal,
                  }}
                  open={removeMatchDayDialog}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={() => setRemoveMatchDayDialog(false)}
                  aria-labelledby="classic-modal-slide-title"
                  aria-describedby="classic-modal-slide-description"
                >
                  <DialogTitle
                    id="classic-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                    <h4 className={classes.modalTitle}>
                      Estàs segur que vols eliminar la jornada?
                    </h4>
                  </DialogTitle>
                  <DialogActions className={classes.modalFooter}>
                    <Button
                      color="danger"
                      simple
                      onClick={(event) => {
                        removeMatchday(selectedMatchday._id);
                        setRemoveMatchDayDialog(false);
                      }}
                    >
                      Eliminar
                    </Button>
                    <Button
                      onClick={() => setRemoveMatchDayDialog(false)}
                      color="success"
                      simple
                    >
                      Tancar
                    </Button>
                  </DialogActions>
                </Dialog>
              </GridItem>
              {selectedMatchday !== null && (
                <GridItem xs={12}>
                  <h4 style={{ textAlign: "center" }}>
                    {moment(selectedMatchday.date).format("DD/MM/YYYY")}
                  </h4>
                </GridItem>
              )}
              {matches.map((match) => (
                <GridItem xs={12} sm={12} md={6} key={match._id}>
                  <Card>
                    <CardBody>
                      <GridContainer>
                        <GridItem xs={4}>
                          <GridContainer>
                            <GridItem xs={12}>
                              <h6 style={{ textAlign: "center" }}>
                                {match.teams[0][0]}
                              </h6>
                            </GridItem>
                            <GridItem xs={12}>
                              <h6 style={{ textAlign: "center" }}>
                                {match.teams[0][1]}
                              </h6>
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                        <GridItem xs={4} style={{ textAlign: "center" }}>
                          <Button
                            key={"RB" + match._id}
                            simple
                            color="success"
                            onClick={() => {
                              setResult(match.result);
                              setMatchId(match._id);
                              setResultDialog(true);
                            }}
                            style={{ paddingLeft: "0px", paddingRight: "0px" }}
                          >
                            <h4
                              style={{ textAlign: "center" }}
                              key={"RBR" + match._id}
                            >
                              {match.result[0] + " - " + match.result[1]}
                            </h4>
                          </Button>
                        </GridItem>
                        <GridItem xs={4}>
                          <GridContainer>
                            <GridItem xs={12}>
                              <h6 style={{ textAlign: "center" }}>
                                {match.teams[1][0]}
                              </h6>
                            </GridItem>
                            <GridItem xs={12}>
                              <h6 style={{ textAlign: "center" }}>
                                {match.teams[1][1]}
                              </h6>
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                </GridItem>
              ))}
            </GridContainer>
          </div>
        </div>
      </div>
      {/*<Footer />*/}
    </div>
  );
}
