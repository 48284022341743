import React, { useState, useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

// core components
import Header from "components/Header/Header.js";
//import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import NavPills from "components/NavPills/NavPills.js";

import ReactTable from "react-table-6";
import "react-table-6/react-table.css";

import client from "../../helpers/feathersClient.js";
import defines from "../../helpers/defines.js";

import styles from "assets/jss/material-kit-react/views/profilePage.js";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

export default function MixedPhaseClassificationPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);

  const [participants, setParticipants] = useState([]);
  const [competition, setCompetition] = useState({
    _id: "",
    type: null,
    participants: [],
  });
  const [phases, setPhases] = useState([]);
  const [newPlayerDialog, setNewPlayerDialog] = useState(false);
  const [newParticipantName, setNewParticipantName] = useState("");

  const [tabs, setTabs] = useState([]);

  const mixedPhaseColumns = [
    {
      Header: "Nom",
      accessor: "name",
    },
    {
      Header: "%",
      accessor: "points",
      minWidth: 50,
      style: { textAlign: "center" },
    },
    {
      Header: "J",
      id: "matches",
      accessor: (d) => d.matchesWon + d.matchesDrawn + d.matchesLost,
      minWidth: 50,
      style: { textAlign: "center" },
    },
    {
      Header: "V",
      accessor: "matchesWon",
      minWidth: 50,
      style: { textAlign: "center" },
    },
    {
      Header: "E",
      accessor: "matchesDrawn",
      minWidth: 50,
      style: { textAlign: "center" },
    },
    {
      Header: "D",
      accessor: "matchesLost",
      minWidth: 50,
      style: { textAlign: "center" },
    },
    {
      Header: "PF",
      accessor: "pointsFor",
      minWidth: 50,
      style: { textAlign: "center" },
    },
    {
      Header: "PC",
      accessor: "pointsAgainst",
      minWidth: 50,
      style: { textAlign: "center" },
    },
    {
      Header: "DP",
      accessor: "pointsDifference",
      minWidth: 50,
      style: { textAlign: "center" },
    },
  ];

  function addParticipant(newParticipantName) {
    client
      .service("participants")
      .create({
        competition: competition._id,
        name: newParticipantName,
      })
      .then(() => {
        queryCompetition();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleParticipantChange(event) {
    setNewParticipantName(event.target.value);
  }

  function updateCompetition(competitionUpdated) {
    if (competitionUpdated._id === competition._id || competition._id === "") {
      var participants = competitionUpdated.participants;
      participants.sort(sortParticipants);
      setParticipants(participants);
      //setMaxRows(result.data[0].participants.length)
    }
  }

  function sortParticipants(a, b) {
    const aMatches = a.matchesWon + a.matchesDrawn + a.matchesLost;
    const bMatches = b.matchesWon + b.matchesDrawn + b.matchesLost;
    if (aMatches === 0 && bMatches === 0) {
      return 0;
    }
    if (aMatches === 0) {
      return 1;
    }
    if (bMatches === 0) {
      return -1;
    }
    /*if (b.matchesWon + b.matchesDrawn + b.matchesLost === 0) {
      if (a.matchesWon + a.matchesDrawn + a.matchesLost === 0) {
        return 0;
      } else {
        return -1;
      }
    } else*/
    if (b.points !== a.points) {
      return b.points - a.points;
    }
    /*if (b.setsDifference !== a.setsDifference) {
      return b.setsDifference - a.setsDifference;
    }*/
    return b.pointsDifference - a.pointsDifference;
  }

  function queryCompetition() {
    client
      .service("competitions")
      .get(props.match.params.id)
      .then((competition) => {
        setCompetition(competition);
        updateCompetition(competition);
        client
          .service("phases")
          .find({
            query: {
              competition: competition._id.toString(),
            },
          })
          .then((result) => {
            let phases = result.data;
            phases.forEach(phase => {
              if (phase.participants) {
                phase.participants.sort(sortParticipants);
              }
            });
            setPhases(phases);
            calculateTabs(phases);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  /*function orderTeams(phases) {
    phases.forEach(phase => {
      phase.teams.sort((a, b) => {
        if (b.matchesWon + b.matchesDrawn + b.matchesLost === 0) {
          if (a.matchesWon + a.matchesDrawn + a.matchesLost === 0) {
            return 0;
          } else {
            return -1;
          }
        } else if (b.points !== a.points) {
          return b.points - a.points;
        } else if (b.setsDifference !== a.setsDifference) {
          return b.setsDifference - a.setsDifference;
        } else {
          return b.pointsDifference - a.pointsDifference;
        }
      });
    });
  }*/

  function calculateTabs() {
    let newTabs = [
      {
        tabButton: "General",
        //tabIcon: Dashboard,
        tabContent: (
          <ReactTable
            data={participants}
            columns={mixedPhaseColumns}
            defaultPageSize={40}
            //showPaginationTop
            showPaginationBottom={false}
            className="-striped -highlight"
          />
        ),
      },
    ];
    phases.map((phase) => {
      newTabs.push({
        tabButton: phase.name,
        //tabIcon: Dashboard,
        tabContent: (
          <ReactTable
            data={phase.participants}
            columns={mixedPhaseColumns}
            defaultPageSize={40}
            //showPaginationTop
            showPaginationBottom={false}
            className="-striped -highlight"
          />
        ),
      });
    });
    setTabs(newTabs);
  }

  useEffect(() => {
    calculateTabs();
  }, [participants, phases]);

  useEffect(() => {
    queryCompetition();
    /*client.service("competitions").on("patched", (competition) => {
      updateCompetition(competition);
    });*/
  }, []);

  return (
    <div>
      <Header
        color="transparent"
        brand="La Kompeti"
        rightLinks={
          <HeaderLinks id={props.match.params.id} type={competition.type} />
        }
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/profile-bg.jpg")} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <GridContainer
              justify="center"
              style={{ paddingTop: "30px", paddingBottom: "30px" }}
            >
              <GridItem xs={12} sm={12} md={12}>
                <Button
                  color="primary"
                  simple
                  onClick={() => setNewPlayerDialog(true)}
                >
                  Afegir jugador
                </Button>
                <Dialog
                  classes={{
                    root: classes.center,
                    paper: classes.modal,
                  }}
                  open={newPlayerDialog}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={() => setNewPlayerDialog(false)}
                  aria-labelledby="classic-modal-slide-title"
                  aria-describedby="classic-modal-slide-description"
                >
                  <DialogTitle
                    id="classic-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                    <h4 className={classes.modalTitle}>Afegir jugador</h4>
                  </DialogTitle>
                  <DialogContent
                    id="classic-modal-slide-description"
                    className={classes.modalBody}
                  >
                    <GridContainer>
                      <GridItem xs={12}>
                        <CustomInput
                          id="nom-jugador"
                          inputProps={{
                            placeholder: "Nom del jugador",
                            onChange: handleParticipantChange,
                            value: newParticipantName,
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                  </DialogContent>
                  <DialogActions className={classes.modalFooter}>
                    <Button
                      color="success"
                      simple
                      onClick={(event) => {
                        addParticipant(newParticipantName);
                        setNewPlayerDialog(false);
                        setNewParticipantName("");
                      }}
                    >
                      Afegir
                    </Button>
                    <Button
                      onClick={() => setNewPlayerDialog(false)}
                      color="danger"
                      simple
                    >
                      Tancar
                    </Button>
                  </DialogActions>
                </Dialog>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <NavPills color="primary" tabs={tabs} />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      {/*<Footer />*/}
    </div>
  );
}
