import React, { useState, useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";
import Email from "@material-ui/icons/Email";
import People from "@material-ui/icons/People";
import Add from "@material-ui/icons/Add";

// core components
import Header from "components/Header/Header.js";
//import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Paginations from "components/Pagination/Pagination.js";

import client from "../../helpers/feathersClient.js";
import defines from "../../helpers/defines.js";

import moment from "moment";

import styles from "assets/jss/material-kit-react/views/profilePage.js";
import { element } from "prop-types";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

export default function PhaseMatchDaysPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);

  const [participants, setParticipants] = useState([]);
  const [matches, setMatches] = useState([]);
  const [matchDays, setMatchDays] = useState([]);
  const [selectedMatchday, setSelectedMatchday] = useState(null);
  const [competition, setCompetition] = useState({
    _id: "",
    type: null,
    participants: [],
  });
  const [phases, setPhases] = useState([]);
  const [selectedPhase, setSelectedPhase] = useState({
    _id: "",
  });
  const [newPhaseDialog, setNewPhaseDialog] = useState(false);
  const [removePhaseDialog, setRemovePhaseDialog] = useState(false);
  const [newPhaseName, setNewPhaseName] = useState("");
  const [resultDialog, setResultDialog] = useState(false);
  const [result, setResult] = useState([
    [0, 0],
    [0, 0],
    [0, 0],
  ]);
  //const [matchId, setMatchId] = useState("");
  const [participantsToSelect, setParticipantsToSelect] = useState([]);
  const [newPhaseTeams, setNewPhaseTeams] = useState([]);
  const [newTeamDialog, setNewTeamDialog] = useState(false);
  const [newTeam, setNewTeam] = useState({
    participants: ["", ""],
    matchesWon: 0,
    matchesDrawn: 0,
    matchesLost: 0,
    setsWon: 0,
    setsLost: 0,
    setsDifference: 0,
    pointsFor: 0,
    pointsAgainst: 0,
    pointsDifference: 0,
    points: 0,
  });
  const [selectedMatch, setSelectedMatch] = useState(null);

  function handlePhaseNameChange(event) {
    setNewPhaseName(event.target.value);
  }

  function addNewPhase(newPhaseName, newPhaseTeams) {
    client
      .service("phases")
      .create({
        competition: competition._id.toString(),
        name: newPhaseName,
        teams: newPhaseTeams,
      })
      .then(() => {
        queryCompetition();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  /*function addMatchday(date, competition, participants) {
    client
      .service("matchdays")
      .create({
        date: date,
        competition: competition,
        participants: participants,
      })
      .then((matchday) => {
        var newMatchDays = [...matchDays];
        newMatchDays.unshift(matchday);
        setMatchDays(newMatchDays);
        setSelectedMatchday(matchday);
        queryMatches(matchday._id);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function removeMatchday(id) {
    client
      .service("matchdays")
      .remove(id)
      .then(() => {
        queryCompetition();
      })
      .catch((error) => {
        console.log(error);
      });
  }*/

  function removePhase(id) {
    client
      .service("phases")
      .remove(id)
      .then(() => {
        queryCompetition();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  /*function addParticipantClick() {
    client.service("participants").create({
      competition: competitionId,
      name: "Enric",
    });
  }*/

  /*function handleParticipantChange(event) {
    setNewParticipantName(event.target.value);
  }*/

  /*function updateCompetition(_id) {
    if (competition._id === _id) {
      queryMatchdays(_id);
    }
  }*/

  function queryMatches(_id) {
    client
      .service("matches")
      .find({ query: { matchday: _id } })
      .then((result) => {
        setMatches(result.data);
      });
  }

  function queryMatchdays(_id) {
    client
      .service("matchdays")
      .find({
        query: {
          phase: _id,
          $sort: {
            matchdayIndex: 1,
          },
        },
      })
      .then((matchdays) => {
        setMatchDays(matchdays.data);
        if (matchdays.data.length > 0) {
          setSelectedMatchday(matchdays.data[0]);
          queryMatches(matchdays.data[0]._id);
        }
      });
  }

  function queryCompetition() {
    client
      .service("competitions")
      .get(props.match.params.id)
      .then((comptetion) => {
        setCompetition(comptetion);
        queryPhases(comptetion._id);
        //queryMatchdays(comptetion._id);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function queryPhases(_id) {
    client
      .service("phases")
      .find({
        query: {
          competition: _id,
          $sort: {
            date: 1,
          },
        },
      })
      .then((result) => {
        setPhases(result.data);
        if (result.data.length > 0) {
          setSelectedPhase(result.data[0]);
          queryMatchdays(result.data[0]._id);
        }
      });
  }

  function modifyMatch(match, result) {
    client
      .service("matches")
      .patch(match._id, {
        result: result,
      })
      .then((match) => {
        var newMatches = [...matches];
        const i = newMatches.findIndex((element, index, array) => {
          return element._id === match._id;
        });
        newMatches.splice(i, 1, match);
        setMatches(newMatches);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    queryCompetition();
    /*client.service("competitions").on("patched", (competition) => {
      updateCompetition(competition._id);
    });*/
  }, []);

  const handleToggle = (value) => {
    const currentIndex = participants.indexOf(value);
    const newChecked = [...participants];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setParticipants(newChecked);
  };

  return (
    <div>
      <Header
        color="transparent"
        brand="La Kompeti"
        rightLinks={
          <HeaderLinks
            id={props.match.params.id}
            type={defines.competitionTypes.COMPETITION_PHASES_PAIRS}
          />
        }
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal,
        }}
        open={resultDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setResultDialog(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h4 className={classes.modalTitle}>Resultat</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer>
            <GridItem
              xs={8}
              style={{
                textAlign: "center",
              }}
            >
              <GridContainer>
                <GridItem
                  xs={12}
                  style={{
                    paddingTop: "27px",
                    marginBottom: "17px",
                  }}
                >
                  <h4
                    style={{
                      textAlign: "center",
                      marginTop: "0px",
                      marginBottom: "0px",
                    }}
                  >
                    {selectedMatch &&
                      selectedMatch.teams[0][0] +
                        " - " +
                        selectedMatch.teams[0][1]}
                  </h4>
                </GridItem>
                <GridItem
                  xs={12}
                  style={{
                    paddingTop: "27px",
                    marginBottom: "17px",
                  }}
                >
                  <h4
                    style={{
                      textAlign: "center",
                      marginTop: "0px",
                      marginBottom: "0px",
                    }}
                  >
                    {selectedMatch &&
                      selectedMatch.teams[1][0] +
                        " - " +
                        selectedMatch.teams[1][1]}
                  </h4>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={4} style={{ textAlign: "center" }}>
              <GridContainer>
                {selectedMatch &&
                  selectedMatch.result.map((set, setIndex) => (
                    <GridItem
                      key={"S" + setIndex}
                      xs={4}
                      style={{ textAlign: "center" }}
                    >
                      <GridContainer>
                        {set.map((teamPoints, teamIndex) => (
                          <GridItem key={"T" + teamIndex} xs={12}>
                            <CustomInput
                              //labelText={ t('translation:default_duration_s') }
                              //id={"result" + setIndex + teamIndex}
                              //formControlProps={{ fullWidth: true }}
                              inputProps={{
                                type: "number",
                                value: result[setIndex][teamIndex],
                                onChange: (event) => {
                                  const punts = parseInt(event.target.value);
                                  if (punts >= 0) {
                                    result[setIndex][teamIndex] = punts;
                                    setResult([...result]);
                                  }
                                },
                              }}
                            />
                          </GridItem>
                        ))}
                      </GridContainer>
                    </GridItem>
                  ))}
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            color="success"
            simple
            onClick={(event) => {
              modifyMatch(selectedMatch, result);
              setResultDialog(false);
            }}
          >
            Modificar
          </Button>
          <Button onClick={() => setResultDialog(false)} color="danger" simple>
            Tancar
          </Button>
        </DialogActions>
      </Dialog>
      <Parallax small filter image={require("assets/img/profile-bg.jpg")} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <GridContainer
              justify="center"
              style={{ paddingTop: "30px", paddingBottom: "30px" }}
            >
              <GridItem xs={12} sm={6} style={{ textAlign: "center" }}>
                <Paginations
                  pages={phases.map((phase) => {
                    return {
                      text: phase.name,
                      active: phase._id === selectedPhase._id,
                      onClick: () => {
                        setSelectedPhase(phase);
                        queryMatchdays(phase._id);
                      },
                    };
                  })}
                />
              </GridItem>
              <GridItem xs={6} sm={3} style={{ textAlign: "center" }}>
                <Button
                  color="primary"
                  simple
                  onClick={() => {
                    setNewPhaseTeams([]);
                    setParticipantsToSelect(
                      competition.participants.map(
                        (participant) => participant.name
                      )
                    );
                    setNewPhaseDialog(true);
                  }}
                  style={{ paddingLeft: "0px", paddingRight: "0px" }}
                >
                  Afegir fase
                </Button>
                <Dialog
                  classes={{
                    root: classes.center,
                    paper: classes.modal,
                  }}
                  open={newPhaseDialog}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={() => {
                    setNewPhaseDialog(false);
                  }}
                  aria-labelledby="classic-modal-slide-title"
                  aria-describedby="classic-modal-slide-description"
                >
                  <DialogTitle
                    id="classic-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                    <h4 className={classes.modalTitle}>Afegir fase</h4>
                  </DialogTitle>
                  <DialogContent
                    id="classic-modal-slide-description"
                    className={classes.modalBody}
                  >
                    <GridContainer>
                      <GridItem xs={12}>
                        <CustomInput
                          id="nom-fase"
                          inputProps={{
                            placeholder: "Nom de la fase",
                            onChange: handlePhaseNameChange,
                            value: newPhaseName,
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={10}>
                        <GridContainer>
                          <GridItem xs={12}>Equips</GridItem>
                          {newPhaseTeams.map((team) => (
                            <GridItem
                              xs={12}
                              key={
                                team.participants[0] +
                                " - " +
                                team.participants[1]
                              }
                            >
                              {team.participants[0] +
                                " - " +
                                team.participants[1]}
                            </GridItem>
                          ))}
                        </GridContainer>
                      </GridItem>
                      <GridItem xs={2}>
                        <Button
                          justIcon
                          round
                          color="primary"
                          disabled={participantsToSelect.length === 0}
                          onClick={(event) => {
                            setNewTeamDialog(true);
                          }}
                        >
                          <Add className={classes.icons} />
                        </Button>
                        <Dialog
                          classes={{
                            root: classes.center,
                            paper: classes.modal,
                          }}
                          open={newTeamDialog}
                          TransitionComponent={Transition}
                          keepMounted
                          onClose={() => setNewTeamDialog(false)}
                          aria-labelledby="classic-modal-slide-title"
                          aria-describedby="classic-modal-slide-description"
                        >
                          <DialogTitle
                            id="classic-modal-slide-title"
                            disableTypography
                            className={classes.modalHeader}
                          >
                            <h4 className={classes.modalTitle}>
                              Seleccionar jugadors
                            </h4>
                          </DialogTitle>
                          <DialogContent
                            id="classic-modal-slide-description"
                            className={classes.modalBody}
                          >
                            <GridContainer>
                              <GridItem xs={6}>
                                {participantsToSelect.map((participant) => (
                                  <div
                                    key={participant}
                                    className={
                                      classes.checkboxAndRadio +
                                      " " +
                                      classes.checkboxAndRadioHorizontal
                                    }
                                  >
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          tabIndex={-1}
                                          onClick={() =>
                                            setNewTeam({
                                              participants: [
                                                participant,
                                                newTeam.participants[1],
                                              ],
                                              matchesWon: 0,
                                              matchesDrawn: 0,
                                              matchesLost: 0,
                                              setsWon: 0,
                                              setsLost: 0,
                                              setsDifference: 0,
                                              pointsFor: 0,
                                              pointsAgainst: 0,
                                              pointsDifference: 0,
                                              points: 0,
                                            })
                                          }
                                          checked={
                                            participant ===
                                            newTeam.participants[0]
                                              ? true
                                              : false
                                          }
                                          disabled={
                                            participant ===
                                            newTeam.participants[1]
                                              ? true
                                              : false
                                          }
                                          checkedIcon={
                                            <Check
                                              className={classes.checkedIcon}
                                            />
                                          }
                                          icon={
                                            <Check
                                              className={classes.uncheckedIcon}
                                            />
                                          }
                                          classes={{
                                            checked: classes.checked,
                                            root: classes.checkRoot,
                                          }}
                                        />
                                      }
                                      classes={{
                                        label: classes.label,
                                        root: classes.labelRoot,
                                      }}
                                      label={participant}
                                    />
                                  </div>
                                ))}
                              </GridItem>
                              <GridItem xs={6}>
                                {participantsToSelect.map((participant) => (
                                  <div
                                    key={participant}
                                    className={
                                      classes.checkboxAndRadio +
                                      " " +
                                      classes.checkboxAndRadioHorizontal
                                    }
                                  >
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          tabIndex={-1}
                                          onClick={() =>
                                            setNewTeam({
                                              participants: [
                                                newTeam.participants[0],
                                                participant,
                                              ],
                                              matchesWon: 0,
                                              matchesDrawn: 0,
                                              matchesLost: 0,
                                              setsWon: 0,
                                              setsLost: 0,
                                              setsDifference: 0,
                                              pointsFor: 0,
                                              pointsAgainst: 0,
                                              pointsDifference: 0,
                                              points: 0,
                                            })
                                          }
                                          checked={
                                            participant ===
                                            newTeam.participants[1]
                                              ? true
                                              : false
                                          }
                                          disabled={
                                            participant ===
                                            newTeam.participants[0]
                                              ? true
                                              : false
                                          }
                                          checkedIcon={
                                            <Check
                                              className={classes.checkedIcon}
                                            />
                                          }
                                          icon={
                                            <Check
                                              className={classes.uncheckedIcon}
                                            />
                                          }
                                          classes={{
                                            checked: classes.checked,
                                            root: classes.checkRoot,
                                          }}
                                        />
                                      }
                                      classes={{
                                        label: classes.label,
                                        root: classes.labelRoot,
                                      }}
                                      label={participant}
                                    />
                                  </div>
                                ))}
                              </GridItem>
                            </GridContainer>
                          </DialogContent>
                          <DialogActions className={classes.modalFooter}>
                            <Button
                              color="success"
                              simple
                              onClick={(event) => {
                                if (
                                  newTeam.participants[0] !==
                                    newTeam.participants[1] &&
                                  newTeam.participants[0] !== "" &&
                                  newTeam.participants[1] !== ""
                                ) {
                                  setNewPhaseTeams([...newPhaseTeams, newTeam]);
                                  setParticipantsToSelect(
                                    participantsToSelect.filter(
                                      (participant) =>
                                        participant !==
                                          newTeam.participants[0] &&
                                        participant !== newTeam.participants[1]
                                    )
                                  );
                                  setNewTeamDialog(false);
                                }
                              }}
                            >
                              Afegir
                            </Button>
                            <Button
                              onClick={() => setNewTeamDialog(false)}
                              color="danger"
                              simple
                            >
                              Tancar
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </GridItem>
                    </GridContainer>
                  </DialogContent>
                  <DialogActions className={classes.modalFooter}>
                    <Button
                      color="success"
                      simple
                      onClick={(event) => {
                        addNewPhase(newPhaseName, newPhaseTeams);
                        setNewPhaseDialog(false);
                        setNewPhaseName("");
                      }}
                    >
                      Afegir
                    </Button>
                    <Button
                      onClick={() => setNewPhaseDialog(false)}
                      color="danger"
                      simple
                    >
                      Tancar
                    </Button>
                  </DialogActions>
                </Dialog>
              </GridItem>
              <GridItem xs={6} sm={3} style={{ textAlign: "center" }}>
                <Button
                  color="primary"
                  simple
                  onClick={() => {
                    if (selectedPhase !== null) setRemovePhaseDialog(true);
                  }}
                  style={{ paddingLeft: "0px", paddingRight: "0px" }}
                >
                  Eliminar fase
                </Button>
                <Dialog
                  classes={{
                    root: classes.center,
                    paper: classes.modal,
                  }}
                  open={removePhaseDialog}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={() => setRemovePhaseDialog(false)}
                  aria-labelledby="classic-modal-slide-title"
                  aria-describedby="classic-modal-slide-description"
                >
                  <DialogTitle
                    id="classic-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                    <h4 className={classes.modalTitle}>
                      Estàs segur que vols eliminar la fase?
                    </h4>
                  </DialogTitle>
                  <DialogActions className={classes.modalFooter}>
                    <Button
                      color="danger"
                      simple
                      onClick={(event) => {
                        removePhase(selectedPhase._id);
                        setRemovePhaseDialog(false);
                      }}
                    >
                      Eliminar
                    </Button>
                    <Button
                      onClick={() => setRemovePhaseDialog(false)}
                      color="success"
                      simple
                    >
                      Tancar
                    </Button>
                  </DialogActions>
                </Dialog>
              </GridItem>
              <GridItem xs={12} sm={6} style={{ textAlign: "center" }}>
                <CustomDropdown
                  buttonText="Jornades"
                  dropdownHeader="Escull jornada"
                  buttonProps={{
                    className: classes.navLink,
                    color: "transparent",
                  }}
                  dropdownList={matchDays.map((matchday) => (
                    <span
                      key={"MDI" + matchday.matchdayIndex}
                      onClick={() => {
                        queryMatches(matchday._id);
                        setSelectedMatchday(matchday);
                      }}
                    >
                      {"Jornada " + (matchday.matchdayIndex + 1)}
                    </span>
                  ))}
                />
              </GridItem>
              {selectedMatchday !== null && (
                <GridItem xs={12}>
                  <h4 style={{ textAlign: "center" }}>
                    {"Jornada " + (selectedMatchday.matchdayIndex + 1)}
                  </h4>
                </GridItem>
              )}
              {matches.map((match) => (
                <GridItem xs={12} sm={12} md={6} key={match._id}>
                  <Card>
                    <CardBody>
                      <GridContainer>
                        <GridItem
                          xs={8}
                          style={{
                            textAlign: "center",
                            marginTop: "5px",
                            marginBottom: "5px",
                            paddingTop: "12px",
                            paddingBottom: "12px",
                          }}
                        >
                          <GridContainer>
                            <GridItem xs={12} style={{ textAlign: "center" }}>
                              <h4 style={{ textAlign: "center" }}>
                                {match.teams[0][0] + " - " + match.teams[0][1]}
                              </h4>
                            </GridItem>
                            <GridItem xs={12}>
                              <h4 style={{ textAlign: "center" }}>
                                {match.teams[1][0] + " - " + match.teams[1][1]}
                              </h4>
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                        <GridItem xs={4} style={{ textAlign: "center" }}>
                          <GridContainer>
                            {match.result.map((set, index) => (
                              <GridItem
                                key={"RS" + index}
                                xs={4}
                                style={{ textAlign: "center" }}
                              >
                                <Button
                                  simple
                                  color="success"
                                  onClick={() => {
                                    /*setResult(set);
                                    setMatchId(match._id);*/
                                    setSelectedMatch(match);
                                    setResult(
                                      JSON.parse(JSON.stringify(match.result))
                                    );
                                    setResultDialog(true);
                                  }}
                                  style={{
                                    paddingLeft: "0px",
                                    paddingRight: "0px",
                                  }}
                                >
                                  <GridContainer>
                                    <GridItem xs={12}>
                                      <h4 style={{ textAlign: "center" }}>
                                        {set[0]}
                                      </h4>
                                    </GridItem>
                                    <GridItem xs={12}>
                                      <h4 style={{ textAlign: "center" }}>
                                        {set[1]}
                                      </h4>
                                    </GridItem>
                                  </GridContainer>
                                </Button>
                              </GridItem>
                            ))}
                          </GridContainer>
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                </GridItem>
              ))}
            </GridContainer>
          </div>
        </div>
      </div>
    </div>
  );
}
