/*eslint-disable*/
import React, { useState, useEffect } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";

// @material-ui/icons
import { Apps, CloudDownload, DragHandle } from "@material-ui/icons";
import ListIcon from '@material-ui/icons/List';
import SportsTennisIcon from '@material-ui/icons/SportsTennis';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import client from "../../helpers/feathersClient.js";
import defines from "../../helpers/defines.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

export default function HeaderLinks(props) {
  const classes = useStyles();

  const [classicModal, setClassicModal] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  function handleEmailChange(event) {
    //console.log(event);
    setEmail(event.target.value);
  }

  function handlePasswordChange(event) {
    setPassword(event.target.value);
  }

  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        {(props.type === defines.competitionTypes.COMPETITION_MIXED_PLAYERS_PERCENTAGE) &&
          <Link to={"/classification-page/" + props.id} className={classes.listItem}>
            <Button
              color="transparent"
              className={classes.navLink}
            >
              <ListIcon className={classes.icons} /> Classificació
            </Button>
          </Link>
        }
        {(props.type === defines.competitionTypes.COMPETITION_PHASES_PAIRS) &&
          <Link to={"/phase-classification-page/" + props.id} className={classes.listItem}>
            <Button
              color="transparent"
              className={classes.navLink}
            >
              <ListIcon className={classes.icons} /> Classificació
          </Button>
          </Link>
        }
        {(props.type === defines.competitionTypes.COMPETITION_PHASES_MIXED_PLAYERS_PERCENTAGE) &&
          <Link to={"/mixed-phase-classification-page/" + props.id} className={classes.listItem}>
            <Button
              color="transparent"
              className={classes.navLink}
            >
              <ListIcon className={classes.icons} /> Classificació
          </Button>
          </Link>
        }
      </ListItem>
      <ListItem className={classes.listItem}>
        {(props.type === defines.competitionTypes.COMPETITION_MIXED_PLAYERS_PERCENTAGE) &&
          <Link to={"/matchdays-page/" + props.id} className={classes.listItem}>
            <Button
              color="transparent"
              className={classes.navLink}
            >
              <SportsTennisIcon className={classes.icons} /> Jornades
            </Button>
          </Link>
        }
        {(props.type === defines.competitionTypes.COMPETITION_PHASES_PAIRS) &&
          <Link to={"/phase-matchdays-page/" + props.id} className={classes.listItem}>
            <Button
              color="transparent"
              className={classes.navLink}
            >
              <SportsTennisIcon className={classes.icons} /> Jornades
          </Button>
          </Link>
        }
        {(props.type === defines.competitionTypes.COMPETITION_PHASES_MIXED_PLAYERS_PERCENTAGE) &&
          <Link to={"/mixed-phase-matchdays-page/" + props.id} className={classes.listItem}>
            <Button
              color="transparent"
              className={classes.navLink}
            >
              <SportsTennisIcon className={classes.icons} /> Jornades
          </Button>
          </Link>
        }
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          className={classes.navLink}
          onClick={() => {
            setClassicModal(true);
          }}
        >
          <AccountCircleIcon className={classes.icons} /> Log In
          </Button>
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal,
          }}
          open={classicModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setClassicModal(false)}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            {/*<IconButton
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={() => setClassicModal(false)}
                      >
                        <Close className={classes.modalClose} />
                      </IconButton>*/}
            <h4 className={classes.modalTitle}>Sign in</h4>
          </DialogTitle>
          <DialogContent
            id="classic-modal-slide-description"
            className={classes.modalBody}
          >
            <GridContainer>
              <GridItem xs={12}>
                <CustomInput
                  id="email"
                  inputProps={{
                    placeholder: "Email",
                    onChange: handleEmailChange,
                    value: email
                  }}
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12}>
                <CustomInput
                  id="password"
                  inputProps={{
                    type: "password",
                    placeholder: "Password",
                    onChange: handlePasswordChange,
                    value: password
                  }}
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
            </GridContainer>
          </DialogContent>
          <DialogActions className={classes.modalFooter}>
            <Button
              color="success"
              simple
              onClick={() => {
                client
                  .authenticate({
                    strategy: 'local',
                    email: email,
                    password: password
                  })
                  .then(user => {
                    console.log(user);
                  })
                  .catch(error => {
                    console.log(error);
                  })
                setClassicModal(false);
                setEmail("");
                setPassword("");
              }}
            >
              Sign In
              </Button>
            <Button
              onClick={() => setClassicModal(false)}
              color="danger"
              simple
            >
              Tancar
              </Button>
          </DialogActions>
        </Dialog>
      </ListItem>
    </List>
  );
}
